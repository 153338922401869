import * as React from "react";

import {
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { gql, useMutation } from "@apollo/client";

const SEND_PASSWORD_RESET_EMAIL = gql`
  mutation sendPasswordResetEmail($username: String!) {
    sendPasswordResetEmail(input: { username: $username }) {
      user {
        databaseId
      }
    }
  }
`;

const SendPasswordResetEmailForm: React.FC = () => {
  const [sendPasswordResetEmail, { loading, error, data }] = useMutation(
    SEND_PASSWORD_RESET_EMAIL
  );
  const wasEmailSent = Boolean(data?.sendPasswordResetEmail?.user?.databaseId);

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const data: any = new FormData(event.currentTarget);
    const { email } = Object.fromEntries(data);
    sendPasswordResetEmail({
      variables: {
        username: email,
      },
    }).catch((error) => {
      console.error(error);
    });
  }

  if (wasEmailSent) {
    return (
      <p>
        {" "}
        Please check your email. A password reset link has been sent to you.
      </p>
    );
  }

  return (
    <Container
      maxW={{ base: "auto", md: "448px" }}
      py={{ base: "12", md: "24" }}
      px="0px"
      minH="100vh">
      <Stack spacing="8" maxW={{ base: "auto", md: "448px" }}>
        <Stack spacing="6" alignItems="center">
          <Stack
            spacing={{ base: "2", md: "3" }}
            textAlign="center"
            mt="40px !important">
            <Heading size="xl">Forgot your password?</Heading>
            <Text>
              {" "}
              Enter the email associated with your account and you&#39;ll be
              sent a link to reset your password.
            </Text>
          </Stack>
        </Stack>
        <Stack
          spacing="6"
          background="white"
          padding="40px"
          borderRadius="lg"
          boxShadow="0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);">
          <form method="post" onSubmit={handleSubmit}>
            <fieldset disabled={loading} aria-busy={loading}>
              <FormControl pb="4">
                <FormLabel htmlFor="password-reset-email">Email</FormLabel>
                <Input
                  id="password-reset-email"
                  type="email"
                  name="email"
                  autoComplete="email"
                  required
                />
                {error ? (
                  <Text color="red" className="error-message">
                    {error.message}{" "}
                  </Text>
                ) : null}
              </FormControl>
              <Button
                type="submit"
                disabled={loading}
                m="0px auto"
                display="flex">
                {loading ? "Sending..." : "Send password reset email"}
              </Button>
            </fieldset>
          </form>
        </Stack>
      </Stack>
    </Container>
  );
};

export default SendPasswordResetEmailForm;
